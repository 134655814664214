@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

$bg_topBar: rgba(241, 235, 235, 0.503);
$social_hover_color: white;
$hover_color: rgb(129, 116, 116);

body {
  font-family: 'Poppins', sans-serif;

  h1, h2, h3, h4, h5 {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }
}

a {
  text-decoration: none !important;
}

// Top bar scss

.top-bar-container {
  background-color: #CEE9E9;
  // background-image: linear-gradient(
  //                 90deg,
  //                 rgba(0, 139, 139, 0.2),
  //                 rgba(0, 139, 139, 0.1),
  //                 rgba(0, 139, 139, 0.2)
  // );
  padding: 2px 0;

  .social-left {
    display: flex;

    a {
      padding: 3px 0;
      margin: 0 7px 0 0;
      transition: 1s;
      font-weight: 300;
      font-size: 14px;
      text-decoration: none;

      &:hover {
        color: $hover_color;
      }

      i {
        color: darkcyan;
      }
    }
  }

  .social-right {
    display: flex;
    justify-content: flex-end;

    a {
      display: inline-block;
      color: darkcyan;
      border-radius: 3px;
      transition: border 1s;
      font-size: 18px;
      margin: 0 5px;

      &:hover {
        color: cyan;
      }
    }
  }
}

// Nav bar scss

.nav-area {
  background-color: #eefffe;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;

  .nav-logo {
    display: none;

    h1 {
      a {
        text-transform: uppercase;
        text-decoration: none;
        color: rgb(251, 73, 73);
      }
    }
  }

  .nav-drawer {
    border-radius: 7px;
    margin: 5px 0;
    transition: box-shadow 0.5s;
    border: 2px solid rgba(227, 225, 225, 0.421);

    &:hover {
      box-shadow: 0px 5px 20px #e1d1d1;
      cursor: pointer;
    }

    .drawer-item {
      justify-content: space-around;
      display: flex;
      align-items: center;
      height: 100%;

      p {
        padding: 10px 10px;
        margin-bottom: 0;
        position: relative;

        &::before {
          content: "";
          width: 80%;
          height: 2px;
          top: 35px;
          background-color: #a8a0a039;
          position: absolute;
        }
      }

      .add-guests {
        display: flex;
        align-items: center;

        p {
          color: #a8a0a0;
        }

        span {
          background-color: rgb(255, 73, 23);
          padding: 5px 10px;
          border-radius: 50%;
          color: white;
        }
      }
    }
  }

  .nav-settings {
    display: none;

    .setting {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;

      .host {
        a {
          text-decoration: none;
          font-size: 16px;
          color: #767676;
          padding: 5px 10px;
          border-radius: 7px;
          transition: background-color 0.5s;
          border: 1px solid #c0c0c042;

          &:hover {
            background-color: #a8a0a061;
            border: 1px solid #c0c0c042;
            color: darkcyan;
          }
        }
      }

      .language {
        p {
          margin: 0 10px;
          padding: 5px 9px;
          border-radius: 50%;
          transition: background-color 0.5s;
          background: #e1e0e0;

          &:hover {
            background-color: #a8a0a061;
          }
        }
      }

      .hamburger {
        display: flex;
        border: 1px solid rgb(171, 164, 164);
        height: 40px;
        border-radius: 7px;
        align-items: center;
        transition: box-shadow 0.5s;
        font-size: 20px;
        cursor: pointer;

        &:hover {
          box-shadow: 0px 3px 5px 0px #dddada;
        }

        p {
          margin-bottom: 0;
          padding: 0 5px;
          color: #a8a0a0;

          .fa-circle-user {
            color: silver;
            display: flex;
            align-items: center;
            font-size: 30px;
          }
        }
      }
    }
  }
}

.profile-menu {
  background-color: #fff;
  position: fixed;
  width: 250px;
  top: 100px;
  z-index: 9999;
  right: 115px;
  padding: 15px 0;
  border-radius: 7px;
  box-shadow: 0px 5px 50px 5px rgba(183, 172, 172, 0.423);

  a, p {
    transition: all .5s;
    padding: 7px 10px;
    text-decoration: none;
    font-size: 16px;
    color: #2e2929;
    cursor: pointer;

    &:hover {
      background-color: #E9E8E8;
      color: #352e2e;
    }
  }

  span {
    background-color: #E9E8E8;
    height: 1px;
    margin: 10px 0;
  }
}

.login-register {
  position: absolute;
  background: #008b8b;
  left: 0;
  right: 0;
  transform: translate(50% - 50%);
}


.search-menu {
  background-color: #ffffff;
  position: absolute;
  padding: 7px 0;
  z-index: 99;
  width: 80vw;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);

  .search-menu-items {
    background: #f0f3f3;
    border: 1px solid rgb(137, 136, 136);
    border-radius: 17px;

    .menu-item-container {
      border-radius: 17px;
      padding: 5px 15px;
      cursor: pointer;
      transition: background .5s;

      .menu-item {
        p {
          margin: 0;
        }

        input {
          border: none;
          background: transparent;
          outline: none;
        }

        .icon {
          background: red;
          padding: 7px 5px;
          border-radius: 7px;
          color: white;
          font-weight: 600;
        }
      }
    }
  }

  .active {
    background: white;
    box-shadow: 0 0 15px 0 rgb(227, 221, 221);
  }
}

.search-region-card {
  background: #ffffff;
  position: absolute;
  top: 75px;
  left: 180px;
  padding: 20px 30px !important;
  border-radius: 30px;
  box-shadow: 0 0 15px 0 rgb(227, 221, 221);

  .card {
    width: 120px;
    background: transparent;
    border: none;

    button {
      border: 1px solid transparent;
      border-radius: 15px;
      transition: border .5s;
      overflow: hidden;

      &:hover {
        border: 1px solid #2e2929;
      }
    }
  }
}

.choose-date {
  background: #ffffff;
  position: absolute;
  top: 75px;
  left: 50%;
  transform: translate(-50%);
  padding: 20px 30px !important;
  border-radius: 30px;
  box-shadow: 0 0 15px 0 rgb(227, 221, 221);

  .card {
    width: 120px;
    background: transparent;
    border: none;

    button {
      border: 1px solid transparent;
      border-radius: 15px;
      transition: border .5s;
      overflow: hidden;

      &:hover {
        border: 1px solid #2e2929;
      }
    }
  }

  .check-in-card, .check-out-card {
    display: flex;
    justify-content: center;

    .react-datepicker {
      background: transparent;
      border: none;
      width: 100% !important;

      .react-datepicker__month-container {
        .react-datepicker__header {
          border: none;
          background: transparent;
        }

        .react-datepicker__day {
          padding: 5px 25px 5px 13px;
          display: inline-block;
          border-radius: 50px;
        }
      }
    }
  }

  .btn-choose-package {
    button {
      background: transparent;
      border-radius: 7px;
      padding: 3px 10px;
      margin: 5px;
      border: 1px solid rgb(205, 197, 197);
      transition: border .2s;

      &:hover {
        border: 1px solid rgb(90, 88, 88);
      }
    }
  }
}

.guests-card {
  background: #ffffff;
  position: absolute;
  top: 75px;
  right: 180px;
  padding: 20px 30px !important;
  border-radius: 30px;
  box-shadow: 0 0 15px 0 rgb(227, 221, 221);

  .guests-card-item {
    padding: 10px 0;

    .btn-count-container {
      display: flex;
      justify-content: end;
      align-items: center;

      .btn-increase, .btn-decrease {
        background: transparent;
        border: 1px solid rgb(205, 197, 197);
        border-radius: 50px;
        width: 30px;
        height: 30px;
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 7px;
        color: rgb(59, 58, 58);
        transition: border .2s;

        &:hover {
          border: 1px solid rgb(90, 88, 88);
        }
      }

      span {
        margin: 0 10px;
        font-size: 18px;
      }
    }
  }
}

.modal-container {
  padding: 0 !important;

  .modal-dialog {
    .modal-content {
      border-radius: 17px;
    }
  }

  .join-now {
    .btn-login, .btn-signup {
      background: #726e6e;
      color: white;
      border: 1px solid #2e2929;
      transition: all 1s;
      padding: 6px 20px;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 1.5px;
      box-shadow: none;

      &:hover {
        background: transparent;
        color: #2e2929;
      }
    }

    .btn-active {
      background: #2e2929;
    }

    .input-container {
      padding: 10px 30px;

      .input {
        outline: none;
        box-shadow: none;
        letter-spacing: .7px;
      }
    }

    .btn-show-pass {
      background: #b2b4b8;
      box-shadow: none;
      border: none;
      color: #2e2929;
      transition: color .3s;

      i {
        width: 20px;
      }

      &:hover {
        color: white;
      }
    }
  }
}

.calender-container {
  .btn-clear {
    border: none;
    padding: 2px 10px;
    border-radius: 5px;
    background: transparent;
    transition: background .5s;

    &:hover {
      background: #c4bfbf;
    }
  }

  .react-datepicker {
    background: transparent;
    border: none;
    width: 50% !important;

    .react-datepicker__month-container {
      .react-datepicker__header {
        border: none;
        background: transparent;
      }

      .react-datepicker__day {
        padding: 5px 25px 5px 13px;
        display: inline-block;
        border-radius: 50px;
      }
    }
  }
}

.review-item {
  .progress {
    width: 150px;
    height: 3px;
  }

  .progress-bar {
    width: 95%;
    background: black;
  }
}


// Banner section scss
.banner img {
  height: 500px;
  width: 100%;
  object-fit: cover;
}

// About section scss

.about-section {
  margin: 20px 0;
  line-height: 25px;

  .section-heading {
    h1 {
      text-align: center;
      text-transform: uppercase;
      padding: 25px 0;
      font-size: 28px;
    }
  }

  .row {
    .content-left {
      padding-bottom: 20px;

      .image-left {
        padding: 0 0;

        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
          object-position: top;
        }
      }
    }

    .text-container {
      padding: 0;

      .border-left {
        display: none;
        height: 100%;
        width: 15px;
        background-color: rgb(234, 241, 241);
        margin: 0 10px;
        position: relative;

        &::before {
          content: "";
          height: 50px;
          width: 5px;
          background-color: #a8a0a0;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }

  .content-right {
    padding-bottom: 55px;

    .image-right {
      padding: 0 0;

      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        object-position: top;
      }
    }
  }

  .text-container-bottom {
    display: flex;
    padding: 0;

    .border-right {
      display: none;
      height: 100%;
      width: 15px;
      background-color: rgb(234, 241, 241);
      margin: 0 10px;
      position: relative;
      order: 1;

      &::before {
        content: "";
        height: 50px;
        width: 5px;
        background-color: #a8a0a0;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}

// Footer section scss

.footer-area {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid #ddd;
  background-color: white;

  .footer-left {
    .footer-link {
      display: flex;
      align-items: center;

      ul {
        display: flex;
        align-items: center;
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        li {
          margin: 0 5px;
          position: relative;

          &:before {
            content: "";
            width: 100%;
            height: 1px;
            background-color: #a8a0a0;
            position: absolute;
            bottom: 0;
            transition: 0.5s;
          }

          a {
            text-decoration: none;
            color: rgb(104, 98, 98);

            &:hover {
              color: #a8a0a0;
            }
          }
        }
      }
    }
  }

  .footer-right {
    display: flex;
    justify-content: flex-end;

    .language {
      margin: 0 5px;
    }

    .currency {
      margin: 0 5px;

      span {
        margin-right: 5px;
      }
    }

    .support {
      cursor: pointer;
    }
  }

  .footer-big {
    z-index: 999;
    background-color: #a8a0a0;
    width: 400px;
  }
}

.subscribe {
  input {
    border: 2px solid #008b8b;
    border-radius: 10px 0 0 10px;

    &:focus {
      border-color: #48b9b9;
      box-shadow: none;
    }
  }
}

.download-app {
  img {
    width: 150px;
    padding: 0 10px;
  }
}

.lead {
  text-align: justify !important;
}

.text-theme {
  color: darkcyan;
}

.section-gap {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

.view-more-button {
  background: transparent;
  border: 2px solid darkcyan;
  padding: 10px 30px;
  border-radius: 10px;
  color: darkcyan;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: darkcyan;
    color: white;
  }
}

@keyframes newitem {
  0% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(-0px);
  }
}

.category-area {
  .category-item {
    text-decoration: none;
    color: rgb(85, 107, 107);
    margin: 5px 0;
    transition: color .5s;
    cursor: pointer;

    img {
      width: 30px;
      height: 30px;
      margin: auto;
    }

    p {
      text-align: center;
      margin-bottom: 0;
      font-size: 14px;
    }

    &:hover {
      color: rgb(19, 106, 112);
    }
  }

  .slick-prev {
    margin-left: -10px;
  }

  .slick-next {
    margin-right: -15px;
  }
}

.filter {
  border: #767676 1px solid;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  transition: box-shadow .5s;
  cursor: pointer;

  button {
    background-color: transparent;
    border: none;
  }

  &:hover {
    box-shadow: 0px 5px 5px 0px #dddada;
  }
}

.home-card-item {
  box-shadow: 0 0 10px silver;
  border-radius: 10px;
  transition: transform 0.5s ease-in-out;

  .image-container {
    position: relative;

    button, .btn-favorite {
      border: none;
      position: absolute;
      background-color: transparent;
      color: rgb(32, 81, 108);
      font-size: 22px;
      right: 5px;
      top: 5px;
    }

    span {
      background-color: #008b8b;
      padding: 3px;
      border-radius: 4px;
      position: absolute;
      text-align: center;
      bottom: -15px;
      width: 50px;
      right: 10px;
      color: white;
      animation: newitem 1s infinite alternate;
    }
  }

  .content {
    padding: 15px;

    h6 {
      color: darkcyan;
    }

    p {
      font-size: 14px;
      margin: 0;
      color: gray;

      strong {
        color: darkcyan;
      }
    }

    div {
      .favourite {
        border: none;
        background-color: transparent;
      }

      i {
        color: gray;
        font-size: 20px;
      }
    }
  }

  &:hover {
    transform: scale(1.1);
  }
}

.single-page-header {
  .review {
    width: max-content;
    padding: 0 0 0 0;
  }

  .location {
    width: max-content;
    padding: 0;
  }

  .btn-shear {
    border: none;
    background: transparent;
    border-radius: 7px;
    transition: background .3s;

    a {
      color: #2e2929;
    }

    &:hover {
      background: #f1f1f1;
    }
  }
}

.single-page-gallery {
  .image-radius {
    border-radius: 7px;
    width: 100%;
  }
}

.icon {
  width: 75px;
}

.single-item-container {
  border-bottom: 1px solid rgb(221, 216, 216);
  padding: 20px 0;

  .image-container {
    width: 60px;
    height: 60px;
  }

  .items-container {
    margin: 10px 0;

    .icon {
      width: 30px;
      font-size: 25px;
      text-align: center;
      margin-right: 5px;
      color: #626b78;
    }
  }
}

.card-container {
  .card {
    border: 1px solid rgb(221, 216, 216);
    border-radius: 17px;
    background: transparent;

    .card-body {
      padding: 20px 30px;

      .icon {
        font-size: 25px;
        color: #626b78;
      }

      .card-text {
        margin: 5px 0;
        font-size: 18px;
      }
    }
  }
}


.slick-next {
  right: 25px !important;

  &::before {
    font-size: 35px !important;
    color: #777b7b67 !important;
  }
}

.slick-prev {
  left: 10px !important;
  z-index: 99;

  &::before {
    font-size: 35px !important;
    color: #777b7b67 !important;
  }

}

.slick-dots {
  bottom: 25px !important;
}

.logo {
  width: 170px;
}
.special-feature{
  box-shadow: 0 0 10px silver;
  border-radius: 10px 0;
  transition: .3s all ease-in-out;
  cursor: pointer;
  &:hover{
    transform: scale(1.1);
  }
}
.amenity-photo {
  width: 75px !important;
}
.booking {
  border: 1px solid silver;
  width: 100%;
  background: #da70d626;
  padding: 5px;
}

.loader {
  position: static !important;
  height: 18px !important;
  width: 45px !important;
  object-fit: cover !important;
}

.login-error-msg {
  color: red;
  position: absolute;
}
.section-gap{
  margin-top: 75px;
  margin-bottom: 75px;
}
